import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Sections from "../components/sections"
import { PageBreadcrumb } from "../components/shared"
import { BlogList } from "../components/blogs"
import { PartnerList } from "../components/partners"
import { EventList } from "../components/events"
import { OpportunityList } from "../components/opportunities"
import LegalSection from "../components/sections/LegalSection"

import { Container, Collapse, Card, CardBody, CardHeader } from "reactstrap"

import "../assets/main.scss"

export const query = graphql`
  query PageQuery($slug: String!) {
    strapiGlobal {
      siteName
      favicon {
        localFile {
          publicURL
        }
      }
      defaultSeo {
        robots
        metaDescription
        metaTitle
        keywords
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    strapiPage(slug: { eq: $slug }) {
      title
      subtitle
      slug
      showBreadcrumb
      seo {
        keywords
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        robots
      }
      subscribe {
        buttonLabel
        title
        type
      }
      sections {
        ... on StrapiPageSections {
          background
          title
          subtitle
          hero
          content
          media {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          media_url
          strapi_component
          action {
            newWindow
            label
            primary
            url
          }
          lead_capture {
            buttonLabel
            title
            type
          }
          review_items {
            ... on StrapiPageSectionsReview_items {
              author
              content
              media_url
            }
          }
          feature_items {
            ... on StrapiPageSectionsFeature_items {
              actions {
                ... on StrapiPageSectionsFeature_itemsActions {
                  label
                  newWindow
                  primary
                  url
                }
              }
              content
              title
              imagePosition
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          profile_items {
            ... on StrapiPageSectionsProfile_items {
              id
              name
              title
              picture {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { strapiPage, strapiGlobal } = props.data
  let isHomePage = props.path === "/"
  let sections
  switch (strapiPage.slug) {
    case "blogs":
      sections = <BlogList />
      break
    case "events":
      sections = <EventList />
      break
    case "partners":
      sections = <PartnerList />
      break
    case "opportunities":
      sections = <OpportunityList />
      break
    case "privacy":
    case "terms":
    case "cookie-policy":
      sections = <LegalSection section={strapiPage} />
      break
    default:
      sections = (
        <Sections
          sections={strapiPage.sections}
          path={props.path}
          isHomePage={isHomePage}
        />
      )
      break
  }
  return (
    <Layout
      seo={strapiPage.seo}
      strapiGlobal={strapiGlobal}
      isHomePage={isHomePage}
    >
      <PageBreadcrumb page={strapiPage} isHomePage={isHomePage} />
      {sections}
    </Layout>
  )
}

export default IndexPage
