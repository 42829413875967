import React from "react"
import { Link } from "gatsby"
import { Card, CardBody, CardImg } from "reactstrap"
import FeatherIcon from "feather-icons-react"

import { currencyFormatter } from "../../utils"
import CarImage from "../../assets/images/car-icon.png"
import BathImage from "../../assets/images/bath-icon.png"
import CalendarImage from "../../assets/images/calender-icon.png"
import ChatImage from "../../assets/images/chat-icon.png"
import StarImage from "../../assets/images/star-icon.png"

const BASE_URL = "http://localhost:3000"

const OpportunityCard = ({ medias, posted_by, property, title, slug }) => {
  return (
    <>
      <Card className="blog rounded border-0 shadow card">
        <div className="position-relative">
          <CardImg
            top
            src={`https://cms.bettercapital.us${medias[0].url}`}
            className="rounded-top content-card-image"
            alt=""
          />
          <div className="overlay rounded-top bg-dark"></div>
          {property.neighborhood_rating && (
            <div className="date-posted">
              <img src={StarImage} alt="starimage" />
              <div className="star-text">{property.neighborhood_rating}</div>
            </div>
          )}
          {property.status === "sold" ? (
            <div className="button-sold">Sold Out</div>
          ) : (
            <div className="button-available">Available</div>
          )}
          <div
            onClick={() =>
              window.open(`${BASE_URL}/opportunity/${slug}`, "_blank")
            }
          >
            <FeatherIcon icon="bookmark" className="fea icon-md heart-icon" />
          </div>
        </div>
        <CardBody className="card-content">
          <div>
            <h6>{title}</h6>
          </div>
          <hr className="card-margin-ruler" />
          <div className="d-flex justify-content-between align-items-center price-container">
            <div className="d-flex justify-content-center align-items-start flex-column">
              <div className="price-details">Price</div>
              <h5 className="mb-0 font-weight-bold">
                {currencyFormatter(property.sale_price)}
              </h5>
            </div>
            <div className="d-flex justify-content-center align-items-start flex-column">
              <div className="price-details">Rent</div>
              <h6 className="mb-0">{currencyFormatter(property.rent)}/m</h6>
            </div>
            <div className="d-flex justify-content-center align-items-start flex-column">
              <div className="price-details">Gross Yeild</div>
              <h6 className="mb-0">10%</h6>
            </div>
          </div>
          <hr className="card-margin-ruler" />
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center sale-details">
              <img src={CarImage} alt="bed" />
              <span className="sale-details-number"> {property.beds} </span>
            </div>
            <div className="d-flex justify-content-center align-items-center sale-details">
              <img src={BathImage} alt="bath" />
              <span className="sale-details-number"> {property.baths}</span>
            </div>
            <div className="d-flex justify-content-center align-items-center sale-details">
              <FeatherIcon icon="grid" className="fea icon-md sale-icon" />
              <span className="sale-details-number">{property.lot_size}</span>
            </div>
            <div className="d-flex justify-content-center align-items-center sale-details">
              <img src={CalendarImage} alt="calendar" />
              <span className="sale-details-number">{property.year_build}</span>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <Link to={`/opportunities/${slug}`} className="email-button">
              <img src={ChatImage} alt="chat" /> &nbsp;&nbsp;&nbsp;
              {posted_by.name}
            </Link>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default OpportunityCard
