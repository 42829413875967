import React from "react"
import { get } from "lodash"
import { Col, Card, CardBody } from "reactstrap"
import readingTime from "reading-time"
import { GatsbyImage } from "gatsby-plugin-image"
import { withPrefix, Link } from "../../utils"

const BlogCard = props => {
  const { post, post_idx } = props
  let article = get(post, "node", null)

  if (!article) return

  let imgSrc = get(
    article,
    "image.localFile.childImageSharp.gatsbyImageData",
    null
  )

  let url = `blogs/${article.slug.toLowerCase()}`
  let readTime = readingTime(article.content)
  return (
    <Col lg="4" md="6" className="mb-4 pb-2" key={post_idx} name="blog">
      <Link to={withPrefix(url)}>
        <Card className="blog rounded border-0 shadow overflow-hidden">
          <div className="position-relative">
            <GatsbyImage image={imgSrc} className="rounded-top" alt="" />
            <div className="overlay rounded-top bg-dark"></div>
          </div>
          <CardBody className="content">
            <h5>
              <Link to={withPrefix(url)} className="card-title title text-dark">
                {article.title}
              </Link>
            </h5>
            <div className="post-meta d-flex justify-content-between mt-3">
              <ul className="list-unstyled mb-0">
                <li className="list-inline-item mr-2  mb-0">
                  <Link to={withPrefix(url)} className="text-muted readmore">
                    <i className="mdi mdi-book-open mr-1"></i>
                    {readTime.text}
                  </Link>
                </li>
              </ul>
              <Link to={withPrefix(url)} className="text-muted readmore">
                Read More <i className="mdi mdi-chevron-right"></i>
              </Link>
            </div>
          </CardBody>
          <div className="author">
            <small className="text-light user d-block">
              <i className="mdi mdi-account"></i> {article.author.name}
            </small>
            <small className="text-light date">
              <i className="mdi mdi-calendar-check"></i> {article.postedOn}
            </small>
          </div>
        </Card>
      </Link>
    </Col>
  )
}

export default BlogCard
