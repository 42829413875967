import React from "react"
import { Link, withPrefix } from "../../utils"
import { Form, Input, Card, CardBody } from "reactstrap"
import { startCase, map } from "lodash"
import { GatsbyImage } from "gatsby-plugin-image"

//Import Icons
import FeatherIcon from "feather-icons-react"

const BlogSidebar = props => {
  const { categoryCounts, recentBlogs } = props

  return (
    <React.Fragment>
      <Card className="border-0 sidebar sticky-bar rounded shadow">
        <CardBody>
          {/* <div className="widget mb-4 pb-2">
            <h5 className="widget-title">Search</h5>
            <div id="search2" className="widget-search mt-4 mb-0">
              <Form
                role="search"
                method="get"
                id="searchform"
                className="searchform"
              >
                <div>
                  <Input
                    type="text"
                    className="border rounded"
                    name="s"
                    id="s"
                    placeholder="Search Keywords..."
                  />
                  <Input type="submit" id="searchsubmit" value="Search" />
                </div>
              </Form>
            </div>
          </div> */}

          <div className="widget mb-4 pb-2">
            <h5 className="widget-title">Catagories</h5>
            <ul className="list-unstyled mt-4 mb-0 blog-categories">
              {map(categoryCounts.group, (category, category_idx) => {
                let url = `category/${category.fieldValue.toLowerCase()}`
                return (
                  <li key={`category-label-${category_idx}`}>
                    <Link to={withPrefix(url)}>
                      {startCase(category.fieldValue)}
                    </Link>{" "}
                    <span className="float-right">{category.totalCount}</span>
                  </li>
                )
              })}
            </ul>
          </div>

          <div className="widget mb-4 pb-2">
            <h5 className="widget-title">Recent Post</h5>
            <div className="mt-4">
              {map(recentBlogs.edges, (blog, blog_idx) => {
                let article = blog.node
                if (!article) return

                let url = `blogs/${article.slug.toLowerCase()}`

                return (
                  <div
                    key={`recent-blog-${blog_idx}`}
                    className="clearfix post-recent"
                  >
                    <div className="post-recent-thumb float-start">
                      <Link to={withPrefix(url)}>
                        <GatsbyImage
                          alt="img"
                          image={
                            article.image.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          className="img-fluid rounded"
                        />
                      </Link>
                    </div>
                    <div className="post-recent-content float-start">
                      <Link to={withPrefix(url)}>{article.title}</Link>
                      <span className="text-muted mt-2">
                        {article.postedOn}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="widget">
            <h5 className="widget-title">Follow us</h5>
            <ul className="list-unstyled social-icon mb-0 mt-4">
              <li className="list-inline-item ml-1">
                <a
                  href="https://www.facebook.com/BetterCapitalus"
                  target="_blank"
                  rel="noreferrer"
                  className="rounded"
                >
                  <i>
                    <FeatherIcon
                      icon="facebook"
                      className="fea icon-sm fea-social"
                    />
                  </i>
                </a>
              </li>
              <li className="list-inline-item ml-1">
                <a
                  href="https://www.instagram.com/bettercapital.us/"
                  className="rounded"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>
                    <FeatherIcon
                      icon="instagram"
                      className="fea icon-sm fea-social"
                    />
                  </i>
                </a>
              </li>
              <li className="list-inline-item ml-1">
                <a
                  href="https://twitter.com/BetterCapitalUS"
                  className="rounded"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>
                    <FeatherIcon
                      icon="twitter"
                      className="fea icon-sm fea-social"
                    />
                  </i>
                </a>
              </li>
              <li className="list-inline-item ml-1">
                <a
                  href="https://www.linkedin.com/company/bettercapital-us/"
                  className="rounded"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>
                    <FeatherIcon
                      icon="linkedin"
                      className="fea icon-sm fea-social"
                    />
                  </i>
                </a>
              </li>
              <li className="list-inline-item ml-1">
                <a
                  href="https://www.youtube.com/channel/UCFXz9rxX0fGze-Ub0Qda_oA"
                  className="rounded"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>
                    <FeatherIcon
                      icon="youtube"
                      className="fea icon-sm fea-social"
                    />
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default BlogSidebar
