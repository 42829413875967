import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { htmlToReact } from "../../utils"
import { get } from "lodash"

export default function LegalSection(props) {
  const [data, setData] = useState({ loading: true })

  useEffect(async () => {
    const {
      section: { slug },
    } = props
    const fetchData = async () => {
      let url
      switch (slug) {
        case "cookie-policy":
          url =
            "https://www.iubenda.com/api/privacy-policy/55664681/cookie-policy/no-markup"
          break
        case "terms":
          url =
            "https://www.iubenda.com/api/terms-and-conditions/55664681/no-markup"
          break
        default:
        case "privacy":
          url = "https://www.iubenda.com/api/privacy-policy/55664681/no-markup"
          break
      }

      const result = await axios(url)
      setData(result.data)
    }

    fetchData()
  }, [])

  let section = get(props, "section", null)
  if (data.loading) return <div />
  return (
    <section className="section">
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <Card className="card shadow rounded border-0">
              <CardBody>{htmlToReact(data.content)}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
