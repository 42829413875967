import React, { useState } from "react"
import {
  Container,
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import { map, times } from "lodash"
import BlogCard from "./BlogCard"

const BlogList = props => {
  const [currentPage, setCurrentPage] = useState(0)
  const { allStrapiBlog } = useStaticQuery(query)
  let filteredBlogs = allStrapiBlog.edges.slice(
    currentPage * 12,
    (currentPage + 1) * 12
  )
  let numberOfPages = Math.round(allStrapiBlog.totalCount / 12)

  return (
    <section className="section">
      <Container>
        <Row>
          {map(filteredBlogs, (post, post_idx) => {
            return <BlogCard post_idx={post_idx} post={post} />
          })}

          <Col xs={12}>
            <Pagination listClassName="justify-content-center mb-0">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink
                  onClick={() => setCurrentPage(currentPage - 1)}
                  aria-label="Previous"
                >
                  Prev
                </PaginationLink>
              </PaginationItem>
              {times(numberOfPages, page => {
                return (
                  <PaginationItem active={currentPage === page}>
                    <PaginationLink
                      onClick={() => setCurrentPage(page)}
                      active={currentPage === page}
                    >
                      {page + 1}
                    </PaginationLink>
                  </PaginationItem>
                )
              })}
              <PaginationItem disabled={currentPage === numberOfPages - 1}>
                <PaginationLink
                  onClick={() => setCurrentPage(currentPage + 1)}
                  aria-label="Next"
                >
                  Next
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

const query = graphql`
  query blogListQuery {
    allStrapiBlog {
      totalCount
      edges {
        node {
          id
          slug
          title
          description
          category {
            name
            slug
          }
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(width: 400, height: 200)
              }
            }
          }
          keywords
          postedOn
          content
          author {
            name
            picture {
              localFile {
                publicURL
                childImageSharp {
                  fixed(width: 60, height: 60) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default BlogList
