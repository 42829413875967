import React from "react"
import { Container, Row } from "reactstrap"
import { StaticQuery, graphql } from "gatsby"
import { map } from "lodash"
import PartnerCard from "./PartnerCard"

const PartnerList = () => {
  return (
    <StaticQuery
      query={graphql`
        query partnerListQuery {
          allStrapiPartner {
            nodes {
              name
              slug
              logo {
                localFile {
                  publicURL
                }
              }
              verified
              seo {
                keywords
                metaDescription
                metaTitle
                shareImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      fixed(width: 400, height: 200) {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allStrapiPartner }) => {
        return (
          <section className="section">
            <Container>
              <Row>
                {map(allStrapiPartner.nodes, (data, idx) => {
                  return <PartnerCard key={idx} data={data} />
                })}
              </Row>
            </Container>
          </section>
        )
      }}
    />
  )
}

export default PartnerList
